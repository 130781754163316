<script setup lang="ts">
import includes from 'lodash-es/includes'
import { ValidationProvider } from 'vee-validate'
import { ref, computed, watch, nextTick } from 'vue'
import { directive as vOnClickaway } from 'vue-clickaway'
import ui from '/~/core/ui'
import './base-select.scss'
import ButtonIcon from '/~/components/base/button/icon/button-icon.vue'
import BaseField from '/~/components/base/field/base-field.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useInput, baseInputProps } from '/~/composables/base'
import { useUI } from '/~/composables/ui'
import Popup from './popup'
import { useBaseSelect } from './use-base-select'

const props = defineProps({
  ...baseInputProps,
  value: {
    type: [String, Number, Array],
    default: null,
  },
  options: {
    type: [Array, Object],
    default: () => [],
  },
  defaultOption: {
    type: String,
    default: '-',
  },
  arrowIcon: {
    type: String,
    default: '',
  },
  expanded: {
    type: Boolean,
    default: false,
  },
  look: {
    type: String,
    default: '',
    validator: (v) => !v || /simple|health|recroom|border-floated/.test(v),
  },
  border: {
    type: Boolean,
    default: true,
  },
  fullwidthPopup: {
    type: Boolean,
    default: false,
  },
  entryClass: {
    type: String,
    default: 'rounded h-10',
  },
  noLabel: {
    type: Boolean,
    default: false,
  },
  labelClass: {
    type: String,
    default: '',
  },
  selectListClass: {
    type: String,
    default: '',
  },
  selectFirst: {
    type: Boolean,
    default: true,
  },
  popupOffset: {
    type: Object,
    default: () => ({ top: 0 }),
  },
  customSelect: {
    type: Boolean,
    default: false,
  },
  emptyText: {
    type: String,
    default: 'no matching',
  },
  search: {
    type: Object,
    default: () => ({
      enabled: false,
      allowSpace: false,
      fields: [],
      maxlength: Infinity,
    }),
  },
})

const emit = defineEmits<{
  (e: 'open'): void
  (e: 'close'): void
  (e: 'input', value: string | number): void
  (e: 'update:modelValue', value: string | number): void
}>()

const { lockKeyboardAccessArea, unlockKeyboardAccessArea } = useUI()
const keyboardAccessAreaId = 'base-select'

const listRef = ref(null)
const listEmptyRef = ref(null)
const fieldRef = ref(null)
const inputRef = ref(null)

const optionRefs = ref({})

const {
  validationProviderRef,
  isFocused,
  onFocus,
  onBlur,
  onInput,
  validateOnBlur,
} = useInput(props, emit)

const {
  selectedPosition,
  hoverPosition,
  blockHover,
  isOpen,
  groups,
  selected,
  isSelected,
  optionsWithoutGroup,
  onHover,
  onKeyUpHandler,
  onPreSelectHandler,
  onSelectClickHandler,
  onBlurHandler,
  isSelect,
  handleToggle,
  clearInput,
  isHover,
  getOptionsByGroup,
  input,
} = useBaseSelect(props, {
  onInput,
  onBlur,
  hoverFromSearch,
  handleEnterPress,
})

const isNativeSelectVisible = ref(true)

const id = computed(() => `${props.name || 'input'}-${Date.now()}`)

const isExpanded = computed(() => {
  const fieldValue =
    ui.mobile && !props.customSelect ? String(props.value) : selected.value.text

  return (
    props.expanded ||
    (fieldValue !== null && fieldValue !== undefined && fieldValue !== '')
  )
})

const fieldValue = computed(() => {
  return ui.mobile && !props.customSelect
    ? String(props.value)
    : selected.value.text
})

const bindings = computed(() => {
  return ui.mobile && !props.search.enabled
    ? {
        placeholder: props.placeholder,
      }
    : {
        value: fieldValue.value,
        readonly: !props.search.enabled,
        placeholder: props.placeholder,
      }
})

watch(isOpen, (newIsOpen) => {
  if (newIsOpen) {
    emit('open')
    if (
      !props.value &&
      typeof props.options[0]?.value !== 'undefined' &&
      props.selectFirst
    ) {
      onInput(props.options[0]?.value)

      if (ui.mobile && !props.customSelect) {
        isNativeSelectVisible.value = false
        nextTick(() => {
          isNativeSelectVisible.value = true
          nextTick(() => {
            input.value?.click()
          })
        })
      }
    }
    if (!ui.mobile) {
      nextTick(() => {
        const rootElement = listRef.value || listEmptyRef.value

        lockKeyboardAccessArea({
          id: keyboardAccessAreaId,
          rootElement,
          parentElement: fieldRef.value?.$el,
          focusElement: rootElement.querySelector(
            '.base-select__item--selected'
          ),
        })
      })
    }
  } else {
    unlockKeyboardAccessArea(keyboardAccessAreaId)
    emit('close')
  }
})

watch(selectedPosition, (val) => {
  hoverPosition.value = val
})

watch(hoverPosition, (val) => {
  optionRefs.value[props.options[val]?.value]?.focus()
})

function handleEnterPress() {
  if (isOpen.value) {
    selectHovered()
  }
  handleToggle()
}

function selectHovered() {
  if (hoverPosition.value !== null) {
    const option = props.options[hoverPosition.value].value

    onInput(option)
  }
}

function onKeyDownHandler(event) {
  if (ui.mobile) {
    return
  }

  blockHover.value = true

  switch (event.code) {
    case 'Space':
      if (!props.search.allowSpace) {
        event.preventDefault()
      }
      break
    case 'Enter':
      event.preventDefault()
      break
    case 'Escape':
      event.preventDefault()
      isOpen.value = false
      break
    case 'End':
    case 'Home':
    case 'ArrowUp':
    case 'ArrowDown':
    case 'Tab':
      if (event.code === 'Tab' && !isOpen.value) {
        break
      }

      event.preventDefault()
      onPreSelectHandler(event)
      checkOptionVisibility('hovered')
      break
  }
}

function hoverFromSearch(search) {
  const index = props.options.findIndex(
    (el) =>
      el.text.substr(0, search.length).toLowerCase() === search.toLowerCase()
  )

  if (index >= 0) {
    hoverPosition.value = index
    checkOptionVisibility('hovered')
  }
}

function isOptionDisabled(item) {
  return Boolean(
    optionsWithoutGroup.value.find((option) => option.value === item)?.disabled
  )
}

function handleItemClick(item) {
  if (isOptionDisabled(item)) return
  if (includes(Object.prototype.toString.call(item), 'Event')) {
    validateOnBlur(item.target.value)
    onInput(item.target.value)
    isOpen.value = props.search.enabled
  } else {
    validateOnBlur(item)
    onInput(item)
    if (props.search.enabled) {
      nextTick(() => {
        if (input.value) {
          input.value.value = fieldValue.value
        }
      })
    }
    isOpen.value = false
  }
}

async function checkOptionVisibility(type) {
  if (isOpen.value && !ui.mobile && !props.search.enabled) {
    let option

    switch (type) {
      case 'hovered':
        option = props.options[hoverPosition.value]
        break
      case 'selected':
      default:
        option = props.options[selectedPosition.value]
    }

    if (option) {
      const optionRef = optionRefs.value[option.value]

      await nextTick()

      if (optionRef) {
        const offset = optionRef.offsetTop
        const height = optionRef.offsetHeight
        const { scrollTop, offsetHeight } = listRef.value

        if (offset + height > scrollTop + offsetHeight) {
          listRef.value.scrollTop = offset - offsetHeight + height
        } else if (offset < scrollTop) {
          listRef.value.scrollTop = offset
        }
      }
    }
  }
}

function onKeyDownOption(event) {
  if (ui.mobile) {
    return
  }

  switch (event.code) {
    case 'Escape':
      event.preventDefault()
      blockHover.value = true
      isOpen.value = false
      input.value?.focus()
      return
  }

  onKeyDownHandler(event)
}
</script>

<template>
  <validation-provider
    v-slot="{ errors }"
    v-bind="validation"
    ref="validationProviderRef"
    :detect-input="false"
    slim
  >
    <base-field
      ref="fieldRef"
      :input-id="id"
      :label="label"
      :required="required"
      :required-asterisk="requiredAsterisk"
      :disabled="disabled"
      :focused="isFocused"
      :expanded="isExpanded"
      :description="description"
      :error="errors[0] || error"
      :floated="floated || look === 'simple'"
      :border-floated="look === 'border-floated'"
      :icon="!search.enabled ? icon : ''"
      :border="look !== 'health' ? border : false"
      :nolabel="nolabel"
      :class="[
        'base-select',
        !search.enabled && 'base-select__icon-divided',
        noLabel && 'base-select__no-label',
        isOpen && `base-select--open`,
        look && `base-select--${look}`,
      ]"
      data-cy="base-select"
      :entry-class="entryClass"
      :label-class="labelClass"
    >
      <template v-if="search.enabled" #icon>
        <div class="mr-2 h-6 w-6">
          <base-icon :svg="'heroicons/outline/magnifying-glass'" :size="24" />
        </div>
      </template>

      <b v-if="prefix" class="mr-[5px] shrink-0">
        {{ prefix }}
      </b>

      <component
        :is="ui.mobile && !customSelect ? 'select' : 'input'"
        v-if="isNativeSelectVisible"
        :id="id"
        ref="inputRef"
        v-on-clickaway="onBlurHandler"
        v-bind="bindings"
        :class="[
          'base-select__input',
          ui.mobile && 'base-select__input--native',
          look && look === 'recroom' && 'base-select__input--recroom',
        ]"
        :maxlength="search.maxlength"
        autocomplete="off"
        :disabled="disabled"
        @keydown="onKeyDownHandler"
        @keyup="onKeyUpHandler"
        @click="onSelectClickHandler"
        @input="handleItemClick"
        @focus="onFocus"
      >
        <option
          v-if="!isSelected"
          :class="['base-select__hidden-option', 'is-default']"
          value=""
          disabled
          selected
        >
          {{ defaultOption }}
        </option>

        <option
          v-for="option in optionsWithoutGroup"
          :key="option.value"
          :class="[option.hidden && 'base-select__hidden-option']"
          :selected="isSelect(option)"
          :value="option.value"
        >
          {{ option.text }}
        </option>

        <optgroup
          v-for="group in groups"
          :key="group.name"
          :label="group.name"
          role="group"
        >
          <option
            v-for="option in getOptionsByGroup(group.name)"
            :key="option.value"
            :selected="isSelect(option)"
            :value="option.value"
          >
            {{ option.text }}
          </option>
        </optgroup>
      </component>

      <div
        v-if="
          ($scopedSlots.selected && !search.enabled) ||
          ($scopedSlots.selected && search.enabled && isSelected)
        "
        class="pointer-events-none absolute left-3 z-1 flex h-full items-center bg-white"
        :class="[!search.enabled ? 'right-10' : 'right-0']"
      >
        <slot name="selected" :option="selected"></slot>
      </div>

      <template #icon-right>
        <div
          class="base-field__icon base-field__icon--right"
          @click.stop="onSelectClickHandler"
        >
          <button-icon
            v-if="isSelected"
            class="base-select__clear !right-auto z-10"
            icon="heroicons/outline/x-circle"
            size="md"
            @click.stop="clearInput"
          />
          <base-icon
            v-else
            class="base-select__arrow pointer-events-none"
            :class="{
              'base-select__arrow--up': isOpen,
              'base-select__arrow--recroom': look === 'recroom',
            }"
            size="sm"
            :svg="arrowIcon || 'plain/chevron-bottom'"
          />
        </div>
      </template>

      <popup
        :fullwidth="fullwidthPopup"
        :visible="(isOpen && !ui.mobile) || (isOpen && customSelect)"
        :popup-offset="popupOffset"
      >
        <ul
          v-if="optionsWithoutGroup.length"
          ref="listRef"
          :class="[
            'base-select__list',
            look && `base-select__list--${look}`,
            selectListClass,
          ]"
          role="listbox"
          data-test="select-list"
        >
          <template v-for="option in optionsWithoutGroup">
            <li
              v-if="'item' in $scopedSlots"
              :key="option.value"
              @click="handleItemClick(option.value)"
            >
              <slot
                name="item"
                :option="option"
                :hover="isHover(option)"
                :selected="isSelect(option)"
                :handler="handleItemClick"
              />
            </li>
            <li
              v-else-if="option.value !== undefined"
              :key="option.value"
              :ref="(el) => (optionRefs[option.value] = el)"
              :class="[
                'base-select__item',
                isHover(option) && 'base-select__item--hover',
                isSelect(option) && 'base-select__item--selected',
                option.hidden && 'base-select__hidden-option',
                look && `base-select__item--${look}`,
              ]"
              role="option"
              tabindex="0"
              @mouseover="onHover(option)"
              @click="handleItemClick(option.value)"
              @mousedown.prevent
              @keydown="onKeyDownOption"
              @keyup="onKeyUpHandler"
            >
              <span>{{ option.text }}</span>
            </li>
          </template>

          <li
            v-for="group in groups"
            :key="group.name"
            class="base-select__group"
          >
            <div class="base-select__group-title">
              <div v-if="group.icon" class="base-select__group-icon">
                <base-icon :svg="group.icon" size="md" />
              </div>
              <div class="base-select__group-title-text">
                {{ group.name }}
              </div>
            </div>
            <ul role="listbox" class="base-select__group-list">
              <template v-for="option in getOptionsByGroup(group.name)">
                <li
                  :ref="(el) => (optionRefs[option.value] = el)"
                  :key="option.value"
                  :class="[
                    'base-select__item',
                    isSelect(option) && 'base-select__item--selected',
                    isHover(option) && 'base-select__item--hover',
                    look && `base-select__item--${look}`,
                  ]"
                  role="option"
                  @mouseover="onHover(option)"
                  @click="handleItemClick(option.value)"
                  @mousedown.prevent
                >
                  {{ option.text }}
                </li>
              </template>
            </ul>
          </li>
        </ul>
        <div
          v-else
          ref="listEmptyRef"
          :class="[
            'base-select__list',
            look && `base-select__list--${look}`,
            selectListClass,
            '!px-5 !py-5',
          ]"
          role="listbox"
        >
          {{ emptyText }}
        </div>
      </popup>
    </base-field>
  </validation-provider>
</template>
